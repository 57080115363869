<template>
    <div>
        <modal
            class="new-modal-small"
            :name="modal_name"
            transition="nice-modal-fade"
            :delay="100"
            height="auto"
            :min-height="200"
            :min-width="400"
            :pivot-y="0.5"
            :adaptive="true"
            :scrollable="true"
        >
            <div class="v-modal-content d-flex flex-column justify-content-center align-items-center text-center" style="background-color: #fff !important; height: 30em !important;">
                <img src="/static/images/trash-big.svg" style="height: 10rem !important" />
                <div class="mt-5" style="font-size: 16px">
                    <div class="mb-4" style="font-size: 20px; color: #f30d0d">Remove Sharing</div>
                    Do you want to remove sharing with {{ name }}?
                </div>
                <div class="d-flex aligin-items-center justify-content-center mt-6">        
                    <button class="btn btn-des-small mr-4 px-4" style="background: transparent; color: #0d5be6; border: 1px solid #0d5be6; width: 4em" @click="hideSelf">Cancel</button>
                    <button class="btn btn-des-small ml-4 px-4" style="background: #0475f3; color: #fff; border: 1px solid #0475f3; width: 4em" @click="confirmDelete">Remove</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: ["modal_name","name"],
    methods: {
        hideSelf() {
            this.$emit("cancelDelete");
            this.$modal.hide(this.modal_name);
        },
        confirmDelete() {
            this.$emit("deleteSharing");
            this.$modal.hide(this.modal_name);
        }
    }
}
</script>